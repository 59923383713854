.page {
  display: inline-block;
  padding-top: 180px;
  position: relative;
  width: 100%;

  @media (--medium) {
    padding-top: 80px;
  }
}
